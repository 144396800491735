
    import CarouselHardwareCard from "@/components/carousel_cards/carousel_hardware_card.vue";
    import { Component, Vue } from "vue-property-decorator";
    import { GraphUtils } from '../../ts/utils/GraphUtils';

    @Component({
        components: {
            CarouselHardwareCard
        },
    })

    // *****************************************************************************************************************
    export default class MiscHardware extends Vue {
        // Fields ------------------------------------------------------------------------------------------------------
        private readonly hardware = {
            // tslint:disable
            headers: [
                { text: "Name",         align: "left", value: "name", sortable: false },
                { text: "Details",      align: "left", value: "details" },
            ],

            items: [
                {
                    name:   "Corsair 780T White Full Tower PC Case",
                    url:    "",
                    image:  require("@/assets/images/corsair_780t_full_tower_case.png"),
                    details: [
                        "ATX, E-ATX, MicroATX, Mini-ITX, XL-ATX",
                        "Front/Side/Top Panel I/O: 1xHeadphoneOut, 1xMicIn, 2xUSB2.0, 2xUSB3.0",
                        "Fan Bays: 5x120/140mm, 2x120mm"
                    ]
                }
            ]
            // tslint:enable
        };

        private readonly averageRAM = {
            // tslint:disable
            traces: [
                {
                    name: 'System RAM',
                    type: "scatter",
                    x: ["2011", "2013", "2017", "2018", "2019", "2020", "2021"],
                    y: [      4,     4,      8,      8,      8,     16,     16],
                    marker: {
                        color: ['hsl(180, 100%, 50%)',
                                'hsl(180, 100%, 50%)',                                
                                'hsl(180, 100%, 50%)',
                                'hsl(180, 100%, 50%)',
                                'hsl(180, 100%, 50%)',
                                'hsl(180, 100%, 50%)',
                                'hsl(180, 100%, 50%)'],
                        size: 15
                    },
                    line: {
                        shape: 'spline',
                        color: 'hsl(0, 0%, 75%)',
                        width: 2
                    },
                },
                {
                    name: 'Video Card RAM',
                    type: "scatter",
                    x: ["2011", "2013",  "2017", "2018", "2019", "2020", "2021"],
                    y: [      1,     1,       1,      2,      2,      8,      8],
                    marker: {
                        color: ['hsl(60, 100%, 50%)',
                                'hsl(60, 100%, 50%)',
                                'hsl(60, 100%, 50%)',                                
                                'hsl(60, 100%, 50%)',
                                'hsl(60, 100%, 50%)',
                                'hsl(60, 100%, 50%)',
                                'hsl(60, 100%, 50%)'],
                        size: 12
                    },
                    line: {
                        shape: 'spline',
                        color: 'hsl(0, 0%, 75%)',
                        width: 2
                    },
                }
            ],
            // tslint:enable
        };

        // Methods -----------------------------------------------------------------------------------------------------
        private get createAverageRamLayout(): object {
            // tslint:disable
            const layout: any       = GraphUtils.createLayout("Year", "Gigabytes (GB)", 72, 48);
            layout["showlegend"]    = true;
            layout["legend"]        = { x: 0.0, y: 1.0, xanchor: 'left', orientation: "v" };
            // tslint:enable
            return layout;
        }

        // Data function -----------------------------------------------------------------------------------------------
        public data() {
            return {
                caseImagePathAndName:
                    require("@/assets/images/corsair_780t_full_tower_case.png"),

                engineIconPathAndName:
                    require("@/assets/images/icon_engine.png"),

                motherboardImagePathAndName:
                    require("@/assets/images/motherboard_gigabyte_amd_ryzen_x570_aorus_master_am4_pcie_4_0_atx.png"),

                mouseImagePathAndName:
                    require("@/assets/images/mouse.png"),

                pcImagePathAndName:
                    require("@/assets/images/pc.png"),

                ssdImagePathAndName:
                    require("@/assets/images/corsair_force_mp600_1tb_m2_pcie_gen_4_0_nvme_ssd.png"),
            };
        }
    }
